<template>
  <div class="our-doctors">
    <header-table :title="$t('dashboard.allDoctors')"
    
      @updateDate="items = $event.ourDoctors"
      url="hospital/search"
    ></header-table>

    <v-data-table
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :mobile-breakpoint="0"
      @page-count="pageCount = $event"
    >
      <!-- name & image -->
      <template v-slot:item.doctor_name="{ item }">
        <div class="d-flex align-center">
          <span class="font-16 greytext--text">{{ item.doctor_name }}</span>
        </div>
      </template>

      <!-- status -->
      <template v-slot:item.specializations="{ item }">
          <span
            class="font-16 greytext--text"
            >{{ item.specializations }}</span
          >
      </template>

      <!-- date -->
      <template v-slot:item.date="{ item }">
        <span class="font-16 greytext--text">{{ item.date }}</span>
      </template>

      <!-- time -->
      <template v-slot:item.time="{ item }">
        <span class="font-16 greytext--text">{{ item.time }}</span>
      </template>

      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <v-btn
            color="register-primary"
            dark
            elevation="0"
            outlined
            @click="$router.push(`/dashboard/doctors/${item.doctor_id}`)"
            class="mx-1"
          >
            <img
              :src="require('@/assets/images/dashboard/icons/message.svg')"
            />
            <span class="font-16">{{$t('forms.sendMessage')}}</span>
          </v-btn>
          <v-btn
            color="register-primary"
            dark
            elevation="0"
            class="mx-1"
            @click="viewProfile(item.doctor_id)"
          >
            <img
              class="mx-1"
              :src="require('@/assets/images/dashboard/icons/eye.svg')"
            />
            <span class="font-16 white--text">{{$t('dashboard.viewProfile')}}</span>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- profile mini dialog -->
    <v-dialog
      v-model="miniProfileDialog"
      max-width="650px"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
      :key="miniProfileDialog"
    >
      <mini-profile @close="miniProfileDialog = false" :idKey="id" ></mini-profile>
    </v-dialog>
  </div>
</template>

<script>
import MiniProfile from "../../components/dialogs/MiniProfile.vue";
import HeaderTable from "../../components/HeaderTable.vue";
export default {
  components: { HeaderTable, MiniProfile },
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: vm.$t("dashboard.doctorName"),
        sortable: false,
        value: "doctor_name",
        width: "150px",
      },
      {
        text: vm.$t("dashboard.specialist"),
        value: "specializations",
        sortable: false,
        width: "200px",
      },
      { text: vm.$t("dashboard.location"), value: "location", sortable: false, width: "150px" },
      { text: vm.$t("dashboard.date"), value: "date", sortable: false, width: "150px" },
      { text: vm.$t("dashboard.actions"), value: "actions", sortable: false, width: "150px" },
    ],
    items: [],

    miniProfileDialog: false,
    id: ''
  }),
  methods: {
    goTo() {
      this.dialog = true;
    },
    viewProfile(id) {
      this.id = id
      this.miniProfileDialog = true;
    },
    async getData() {
      let { data } = await this.$axios("our/doctors");
      if (data) {
        this.items = data.data.ourDoctors;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.our-doctors {
  border-radius: 15px;
  background: white;
  padding: 8px;
  thead {
    background: $bluebgdash;
    padding-left: 10px;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #202a4f;
    }
  }
  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }
  thead,
  tbody {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 30px !important;
        }
      }
    }
    tr:hover {
    background-color: transparent !important;
  }
  }
  tbody {
    tr {
      height: 70px;
    }
    tr:hover {
    background-color: transparent !important;
  }
  }
}
td,
th {
  border-bottom: none !important;
}
</style>
